// gatsby-browser.js
//We will wrap our application with the Web3JS Provider
import React from "react"

//Web3JS Provider
import {
  Web3ReactProvider,
  // UnsupportedChainIdError
} from "@web3-react/core"
import { ethers } from "ethers"

//Get's the ethers library to hook with our provider
//We can still use Web3JS for contract calls,
//But using Web3 from web3js as the provider did not work
const getLibrary = provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 10000
  return library
}

export const wrapRootElement = ({ element }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>{element}</Web3ReactProvider>
  )
}
